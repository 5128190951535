/* body {
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
} */

.container {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.header {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Email entry rows */
.email-list .entry {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

/* Input field styling */
.email-input {
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

/* Random number display and button container */
.random-number-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

/* Random number styling */
.random-number {
    font-weight: bold;
    color: #2c3e50;
}

/* Copy button styling */
.copy-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 5px;
    /* Add space around the copy button */
    padding: 4px;
    /* Add padding to make the icon area larger */
}

.copy-btn:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
}

/* Feedback message for copied text */
.copied-text {
    color: green;
    font-size: 12px;
    margin-left: 5px;
}

/* Remove button styling */
.remove-btn {
    background-color: #e74c3c;
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
}

.remove-btn:hover {
    background-color: #c0392b;
}

/* Add button styling */
.add-btn {
    width: 100%;
    background-color: #3498db;
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.add-btn:hover {
    background-color: #2980b9;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}


/* Button container styling */
.button-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.add-btn,
.submit-btn {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    border: none;
}

.add-btn {
    background-color: #3498db;
}

.add-btn:disabled {
    background-color: #b0d4f1;
    cursor: not-allowed;
}

.submit-btn {
    background-color: #28a745;
}

.submit-btn:disabled {
    background-color: #a3d3b2;
    cursor: not-allowed;
}

/* Button container styling */
.button-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.add-btn,
.submit-btn {
    width: 100%;
    /* Make both buttons fill the available space equally */
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    border: none;
    text-align: center;
}

.add-btn {
    background-color: #3498db;
}

.add-btn:disabled {
    background-color: #b0d4f1;
    cursor: not-allowed;
}

.submit-btn {
    background-color: #28a745;
}

.submit-btn:disabled {
    background-color: #a3d3b2;
    cursor: not-allowed;
}

/* Container for the CRM multi-select dropdown */
.crm-select {
    margin-bottom: 40px;
    font-family: Arial, sans-serif;
}

.crm-select label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

/* Styling the multi-select dropdown */
.crm-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    appearance: none;
    height: auto;
}

/* Add a custom scroll bar for the dropdown options */
.crm-dropdown option {
    padding: 10px;
    color: #333;
}

/* Highlight selected options */
.crm-dropdown option:checked {
    background-color: #007BFF;
    /* Highlight color for selected options */
    color: white;
}

/* Placeholder styling for when no option is selected */
.crm-dropdown option::placeholder {
    color: #888;
}

/* Dropdown hover and focus effects */
.crm-dropdown:hover,
.crm-dropdown:focus {
    border-color: #007BFF;
    background-color: #f1faff;
}

/* Scrollbar customization */
.crm-dropdown {
    overflow-y: auto;
    max-height: 150px;
    /* Adjust max height for dropdown options */
}

/* Remove default multi-select arrow for custom styling */
.crm-dropdown::-ms-expand {
    display: none;
}

/* CRM Input Box and Add Button Container */
.crm-input-container {
    display: flex;
    flex-direction: column;
    /* Stack label above input */
    gap: 5px;
    /* Adjust spacing between label and input */
    margin-bottom: 20px;
}

/* CRM Input Field */
.crm-input {
    padding: 12px;
    font-size: 16px;
    width: 220px;
    border: 2px solid #ccc;
    border-radius: 6px;
    outline: none;
    transition: border-color 0.3s;
}

.crm-input:focus {
    border-color: #007BFF;
    /* Highlight border on focus */
}

/* Add CRM Button */
.add-crm-btn {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
}

.add-crm-btn:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

.add-crm-btn:active {
    transform: scale(0.98);
    /* Slightly shrink on click */
}

/* Added CRM List */
.crm-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
    margin-bottom: 15px;
}

/* Each CRM Item */
.crm-item {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    color: #333;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
}

/* Remove Button (X) */
.remove-crm-btn {
    margin-left: 6px;
    cursor: pointer;
    background: none;
    border: none;
    color: #ff4d4d;
    /* Red color for delete */
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s;
}

.remove-crm-btn:hover {
    color: #cc0000;
    /* Darker red on hover */
}

.crm-label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: large;
}

.crm-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-crm-text {
    font-size: 12px;
    color: #555;
    margin-left: 10px;
    font-weight: bold;
}

.crm-input {
    padding: 12px;
    font-size: 16px;
    width: 220px;
    border: 2px solid #ccc;
    border-radius: 6px;
    outline: none;
    transition: border-color 0.3s;
}

.crm-input:focus {
    border-color: #007BFF;
}

.section {
    margin-top: 30px;
    margin-bottom: 30px;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.section-subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}